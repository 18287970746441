<template>
  <div>
    <div class="my-5">
      <router-link to="/user/examination" style="font-size: 20px"><i class="fa fa-arrow-circle-o-left me-2"></i>Back
      </router-link>
    </div>
    <div class="row justify-content-between mt-5">
      <!-- <div class="col" v-if="$parent.$data.candidateAssessment.assessment.type !== 'written'"> -->
      <div class="col">
        <timer :start_time="$parent.$data.candidateAssessment.exam_started_at"
               :end_time="$parent.$data.candidateAssessment.exam_end_at"
               :time_spend="$parent.$data.candidateAssessment.time_spend"
               :end-time-call-back="$parent.getAssessment"
        ></timer>
      </div>
      <div class="col">
        <p class="float-end">
          {{ this.$parent.$data.candidateAssessment.step.current_step + 1 }}/
          {{ this.$parent.$data.candidateAssessment.step.question_ids.length }}
        </p>
      </div>
    </div>
    <br>
    <div class="row justify-content-between">
      <div class="col-12">
        <div v-html="question.title"></div>
      </div>
    </div>

    <hr>
    <div class="exam-question" v-for="answer in question.answers">
      <input :type="question.type | htmlQuestionType"
             :id="'answer'+answer.id" name="answer"
             :value="answer.id"
             v-model="given_answer">
      <label :for="'answer'+answer.id">
        <div v-html="answer.title"></div>
      </label>
    </div>
    <template v-if="$parent.$data.candidateAssessment.assessment.type !== 'written'">
      <button style="padding: 12px 48px" class="btn btn-primary my-5" @click="saveAnswer(false)">Next</button>
      <button class="btn btn-link" @click="saveAnswer(true)">Skip</button>
    </template>

    <ol>
      <li v-for="error in form_errors" class="text-red">{{ error }}</li>
    </ol>

<!--    <form @submit.prevent="submitEvaluationUrl" v-if="!$parent.$data.candidateAssessment.assessment.open_to_start">-->
    <form @submit.prevent="submitEvaluationUrl" v-if="$parent.$data.candidateAssessment.assessment.type === 'written'">
      <div class="mb-3">
        <label class="form-label">Your github url</label>
        <input type="url" class="form-control" v-model="evaluation_url" placeholder="Your github url">
      </div>
      <div class="mb-3">
        <label class="form-label" >Your Feedback</label>
        <textarea cols="30" rows="10" v-model="candidate_feedback" placeholder="
          1. We like to know how much did you finish

          2. Please record your final output of the exam and send the video link here.

          3. If you have queries or questions regarding the test that you attend, feel free to put down here in this feedback box.
          " class="form-control" spellcheck="false">

        </textarea>
      </div>

      <p ref="evaluationURLMessage" class="d-none" style="padding-bottom:5px; color:green;">
        Your evaluation url has been submitted successfully, we will review your assessment shortly</p>

      <button type="submit" class="btn btn-primary mb-5">Submit Answer URL</button>

      <div class="mb-5"></div>
    </form>
  </div>
</template>

<script>
import axios from "@/axios";
import Timer from "../../components/Timer";

export default {
  name      : "ExamQuestion",
  components: {
    timer: Timer
  },
  data() {
    return {
      form_errors   : [],
      evaluation_url: '',
      candidate_feedback: '',
      given_answer  : [],
      question      : {
        answers: []
      }
    }
  },
  methods: {

    submitEvaluationUrl() {
      axios.post('/assessment/save-evaluation-url/', {
        evaluation_url : this.evaluation_url,
        candidate_feedback : this.candidate_feedback,
        assessment_uuid: this.$parent.$data.candidateAssessment.unique_id
      }).then(data => {
        this.$refs.evaluationURLMessage.className = 'd-block'
      })
    },

    fetchQuestion() {
      axios.get(`/assessment/${this.$route.params.uuid}/question`).then(response => {
        this.question = response.data
      }).catch(error => {
        if (error.response.data.out_of_step) {
          this.$parent.getAssessment()
          this.$router.push({name: 'examFinish', params: {uuid: this.$route.params.uuid}})
        }
        if (error.response.data.time_up) {
          this.$router.push({name: 'examFinish', params: {uuid: this.$route.params.uuid}})
          // console.log('time up vai , time up')
        }
      })
    },
    async saveAnswer(skip = false) {
      if (this.given_answer.length === 0 && skip === false) {
        alert('please select an answer')
        return ''
      }
      await axios.post('/assessment/save-answer/', {
        uuid       : this.$route.params.uuid,
        question_id: this.question.id,
        answers    : Array.isArray(this.given_answer) ? this.given_answer : [this.given_answer]
      }).then(response => {
        this.$parent.$data.candidateAssessment.step.current_step += 1
        this.given_answer = []
        this.form_errors  = []
      }).catch(error => {
        this.form_errors = error.response.data
      })
      this.fetchQuestion()
    }
  },

  filters: {
    htmlQuestionType(value) {
      if (value === 'single_choice') {
        return 'radio'
      }
      return 'checkbox'
    }
  },

  async mounted() {
    await this.fetchQuestion()
  }
}
</script>

<style scoped>

</style>