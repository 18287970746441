
<template>
  <span>Exam Time : {{ duration }} </span>
</template>

<script>
import moment from 'moment'

export default {
  name: "Timer",
  props: {
    end_time: {
      required: true
    },
    start_time: {
      required: true
    },
    time_spend: {
      required: false
    },
    endTimeCallBack: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      target_minute: 360,
      duration: '',
      countDownToTime : new Date(this.start_time).getTime(),
    }
  },
  filters: {
    clock: (value) => {
      return moment(value).format('hh:mm:ss')
    }
  },
  methods: {
    startTimer: function() {
      const endTime = new Date(this.end_time).getTime() / 1000;
      const timeNow = new Date().getTime() / 1000;
      const remaining = endTime - timeNow

      const d = Math.floor(remaining / 86400)
      const h = Math.floor((remaining % 86400 ) / 3600)
      const m = Math.floor(((remaining % 86400 ) % 3600) / 60)
      const s = Math.floor(((remaining % 86400 ) % 3600) % 60)


      if(remaining > 0 ) {
        let str = ''
        if(d > 0) {
          str = `${d}d ${h}h ${m}m ${s}s`
        } else if (h > 0 ) {
          str = `${h}h ${m}m ${s}s`
        } else if (m > 0) {
          str = `${m}m ${s}s`
        } else {
          str = `${s}s`
        }
        this.duration = str
      } else {
        this.duration = 'Expired'
      }

    },
    abs(value) {
      return Math.abs(value)
    }
  },
  mounted() {



    let spend_minute = this.time_spend.split(':')
    let start_time = moment(this.start_time)
    let end_time = moment(this.end_time)

    setInterval(() => { this.startTimer() }, 1000);



    /*let spend_minute = this.time_spend.split(':')
     // console.log(moment(this.start_time).toString())
     let start_time = moment(this.start_time)
     .subtract(spend_minute[0], "hours")
     .subtract(spend_minute[1], 'minutes')
     .subtract(spend_minute[2].split('.')[0], 'seconds')
     // console.log(start_time.toString())
     let end_time = moment(this.end_time)
     let diffTime = end_time.diff(start_time)
     // console.log(end_time,'end_time',start_time,'diffTime',diffTime)
     let countdownClock = setInterval(() => {
     diffTime += 1000
     if (diffTime >= 0) {
     clearInterval(countdownClock)
     this.endTimeCallBack()
     }

     this.duration = `${Math.floor(this.abs(moment.duration(diffTime).asHours()))}: ${this.abs(moment.duration(diffTime).minutes())}: ${this.abs(moment.duration(diffTime).seconds())}`

     // console.log(this.abs(moment.duration(diffTime).hours()),'this.duration',diffTime)
     }, 1000)*/


  }
}
</script>

<style scoped>

</style>