<template>
  <div class="container">
    <template v-if="!keyPressed">
      <component v-bind:is="currentScreen"></component>
    </template>
    <template v-else>
      <div class="pt-5">
        <h1>Avast !</h1>
        <p>By pressing any keys from you pc will hide the question from you,
          but the timer will do his job as it is. <br>
        </p>
        <br>
        <button class="btn btn-primary btn-lg" @click="keyPressed = false">Resume Exam</button>
      </div>
    </template>

  </div>
</template>

<script>
import axios from "@/axios";
import ExamQuestion from "./ExamQuestion";
import StartScreen from "./StartScreen";
import ExamFinished from "./ExamFinished";

export default {
  name: "Exam",
  components: {
    ExamQuestion, StartScreen, ExamFinished
  },
  data() {
    return {
      keyPressed: false,
      candidateAssessment: {
        assessment: {},
        candidate_job: {}
      }
    }
  },
  methods: {
    async getAssessment() {
      await axios.get(`/assessment/${this.$route.params.uuid}/`).then(res => {
        this.candidateAssessment = res.data
      }).catch(error => {
        console.error(error.response)
      })
    },

    disableKeyBoard(e) {
      this.keyPressed = true
      e.preventDefault()
    },

    disableContextMenu(e) {
      e.preventDefault()
    }
  },
  computed: {
    currentScreen() {
      if (this.candidateAssessment.time_spend === 'time_up') {
        return ExamFinished
      }

      if (this.candidateAssessment.exam_started_at) {
        return ExamQuestion
      }

      return StartScreen
    }
  },
  async mounted() {
    await this.getAssessment()

    if (this.candidateAssessment.assessment.open_to_start) {
      window.addEventListener('contextmenu', this.disableContextMenu)
      window.addEventListener('keydown', this.disableKeyBoard)
    } else {
      window.removeEventListener('contextmenu', this.disableContextMenu, true)
      window.removeEventListener('keydown', this.disableKeyBoard, true)
    }
  }
}
</script>

<style scoped>
.container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>