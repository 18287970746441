<template>
  <div class="container">
    <div class="my-5">
      <router-link to="/user/examination" style="font-size: 20px"><i class="fa fa-arrow-circle-o-left me-2"></i>Back
      </router-link>
    </div>
    <h1 class="mb-4 exam-title">{{ $parent.$data.candidateAssessment.assessment.title }}</h1>
    <div class="d-flex start-exam-heading">
      <div class="flex-item">
        <div class="d-flex align-items-center">
          <img src="/assets/dashboard/score-board.png" alt="">Score :
          <p>{{ $parent.$data.candidateAssessment.assessment.score }}</p>
        </div>
      </div>
      <div class="flex-item">
        <div class="d-flex align-items-center">
          <img src="/assets/dashboard/time.png" alt="">Duration :
          <p>{{ $parent.$data.candidateAssessment.assessment.duration }} Minute</p>
        </div>
      </div>
      <div class="flex-item">
        <div class="d-flex align-items-center">
          <img src="/assets/dashboard/report-card.png" alt="">Pass Score :
          <p>{{ $parent.$data.candidateAssessment.assessment.pass_score }}</p>
        </div>
      </div>
    </div>
    <div v-highlight>
      <div class="exam-description" v-html="$parent.$data.candidateAssessment.assessment.description"></div>
    </div>
    <div v-if="axios_error.length > 0">
      <ol class="list-unstyled">
        <li style="padding-bottom: 10px" class="text-danger" v-for="er in axios_error"><b>{{ Object.values(er)[0] }}</b></li>
      </ol>
    </div>
    <div class="row pt-3 pb-2">
      <div class="col-md-3">
        <button style="padding: 12px 24px; margin-bottom: 20px" @click="startExam" class="btn btn-primary">Start Exam</button>
      </div>
    </div>
    

  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "StartScreen",
  data() {
    return {
      axios_error: [],
      candidateAssessment: {
        assessment: {},
        candidate_job: {}
      }
    }
  },
  methods: {
    startExam() {
      this.axios_error = []
      axios.put(`/assessment/${this.$route.params.uuid}/`).then(res => {
        console.log(res.data)
        this.$parent.getAssessment()
      }).catch(error => {
        this.axios_error.push(error.response.data)
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      })

    }
  },

  mounted() {
    console.log(this.$parent.$data.candidateAssessment)
    this.candidateAssessment = this.$parent.$data.candidateAssessment
  }
}
</script>

<style scoped>
</style>